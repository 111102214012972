import { HiShieldCheck } from "react-icons/hi";
import { MdCancel, MdAnalytics } from "react-icons/md";

const data = [
  {
    icon: <HiShieldCheck/>,
    heading: "Electrical and Solar System",

    detail1: "Substation and transformer.",
    detail2: "Low level voltage distribution.",
    detail3: "Power factor correction.",
    detail4: "Lighting layout and design.",
    detail5: "Small power distribution.",
    detail6: "Segregation of emergency/ normal power.",
    detail7: "UPS system.",
    detail8: "Lighting protection system.",
    detail9: "Clean earth system and safety earthing.",
    detail10: "Tender document.",
  },
  {
    icon: <MdCancel />,
    heading: "Public Health Services",

    detail1: "Sewer and drainage.",
    detail2: "Storm water drains.",
    detail3: "Hot and cold water system.",
    detail4: "Plumbing and sanitation fittings.",
    detail5: "Water treatment system.",
    detail6: "Water storage.",
    detail7: "Effluent treatment and disposal.",
    detail8: "Water distribution for landscaping.",
    detail9: "Rain water harvesting.",
    detail10: "Tender document.",
  },
  {
    icon: <MdAnalytics />,
    heading: "Fire fighting",
    
    detail1: "Fire fighting and hose reel.",
    detail2: "Sprinkler system.",
    detail3: "Smoke and heat detection.",
    detail4: "Hand held extinguisher.",
    detail5: "Emergency warning/ intercom/ PA system",
    detail6: "Tender document.",
    detail7: "Pre construction design stage.",
  },
  {
    icon: <MdCancel />,
    heading: "Pre-construction Design Stage",

    detail1: "Site data collection and analysis.",
    detail2: "Budget preparation.",
    detail3: "Coordination with architect and consultant.",
    detail4: "Preparation of tender and pre qualification.",
  },
  {
    icon: <MdAnalytics />,
    heading: "Construction Stage",

    detail1: "Time management.",
    detail2: "Cost management",
    detail3: "Quality management.",
    detail4: "Material management.",
    detail5: "Documentation.",
    detail6: "Quantity surveying.",
  },
];

export default data;
import React, { useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { MdOutlineArrowDropDown } from "react-icons/md";
import "./services.css";
import data from "../../utils/accordion";

const Services = () => {
  // const [className, setClassName] = useState(null);

  return (
    <section className="services" id="services">
      <div className="service-head">
        <span className="primaryText">Our Services</span>
        <div className="service-underline" />
        <span className="secondaryText">
        From Concept to Completion: Providing Comprehensive
          <br />
          Engineering and Construction Solutions.
        </span>
      </div>
      <div className="service-main">
        <div className="service-flex">
          <div className="service-block">
            <h4>Architectural Planning</h4>
          </div>
          <div className="service-block service-block2">
            <h4>
              Structural Consultancy & Drawing Preparation (RCC & Steel
              structures)
            </h4>
          </div>
          <div className="service-block service-block2">
            <h4>BOQ/ Detailed Estimate Report Preparation & Valuation</h4>
          </div>
          <div className="service-block service-block2">
            <h4>Project Management Consultancy</h4>
          </div>
        </div>

        <div className="service-flex">
          <div className="service-block service-block2">
            <h4>M.E.P Consultancy and services drawing preparation</h4>
          </div>
          <div className="service-block service-block2">
            <h4>Geotechnical Investigations and Report Preparations</h4>
          </div>
          <div className="service-block">
            <h4>Land Survey</h4>
          </div>
          <div className="service-block service-block2">
            <h4>Public health engineering design and estimation</h4>
          </div>
        </div>

        <div className="service-flex">
          <div className="service-block service-block2">
            <h4>Fire hydrant system design and Fire alarm system</h4>
          </div>
          <div className="service-block service-block2">
            <h4>Topographical Survey and many more</h4>
          </div>
          <div className="service-block">
            <h4>Air-conditioning</h4>
          </div>
          <div className="service-block">
            <h4>HVAC</h4>
          </div>
        </div>

        <div className="service-flex-2">
          <div className="service-block">
            <h4>Walk Through</h4>
          </div>
          <div className="service-block">
            <h4>soil and material testing</h4>
          </div>
        </div>
      </div>

      <div className="v-wrapper">
        <div className="paddings innerWidth flexCenter v-container">
          <div className="flexColStart v-right">
            <Accordion
              className="accordion"
              allowMultipleExpanded={false}
              preExpanded={[0]}
            >
              {data.map((item, i) => {
                return (
                  <div className="panel" key={i}>
                    <AccordionItem
                      className='accordionItem'
                      key={i}
                      uuid={i}
                    >
                      <AccordionItemHeading>
                        <AccordionItemButton className="flexCenter accordionButton">
                          {/* <AccordionItemState>
                            {({ expanded }) =>
                              expanded
                                ? setClassName("expanded")
                                : setClassName("collapsed")
                            }
                          </AccordionItemState> */}

                          <div className="flexCenter icon">{item.icon}</div>
                          <span className="primaryText">{item.heading}</span>
                          <div className="flexCenter icon">
                            <MdOutlineArrowDropDown size={20} />
                          </div>
                        </AccordionItemButton>
                      </AccordionItemHeading>

                      <AccordionItemPanel>
                        <p className="secondaryText detail-text">
                          {item.detail1}
                        </p>
                        <p className="secondaryText detail-text">
                          {item.detail2}
                        </p>
                        <p className="secondaryText detail-text">
                          {item.detail3}
                        </p>
                        <p className="secondaryText detail-text">
                          {item.detail4}
                        </p>
                        <p className="secondaryText detail-text">
                          {item.detail5}
                        </p>
                        <p className="secondaryText detail-text">
                          {item.detail6}
                        </p>
                        <p className="secondaryText detail-text">
                          {item.detail7}
                        </p>
                        <p className="secondaryText detail-text">
                          {item.detail8}
                        </p>
                        <p className="secondaryText detail-text">
                          {item.detail9}
                        </p>
                        <p className="secondaryText detail-text">
                          {item.detail10}
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </div>
                );
              })}
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;

import React, { useState } from "react";
import "./nav.css";
import { BrowserRouter } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";

const Nav = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <BrowserRouter>
      <div
        className="menu"
        onClick={() => {
          setMenuOpen(!menuOpen);
        }}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>

      <section className="h-wrapper App">
        <div className="flexCenter paddings innerWidth h-container ">
          {/* logo-image */}
          <img src="./images/logo2.png" alt="logo" width={100} className="main-logo" />

          {/* logo-text */}
          <div className="logo">
            <div>
              <h4 className="logo-first-text">Consult and Constructions</h4>
            </div>
            <div>
              <p className="logo-second-text">
                Engineers <span className="logo-span">|</span> Architects{" "}
                <span className="logo-span">|</span> Contractors
              </p>
            </div>
          </div>
          <div
            className={
              menuOpen ? "flexCenter h-menu open" : "flexCenter h-menu"
            }
          >
            <Link to="#partners">Partners</Link>
            <Link to="#services">Services</Link>
            <Link to="#work">Work</Link>
            <Link to="#about">About</Link>
            <button className="button">
              <Link to="#contact">Contact</Link>
            </button>
          </div>
        </div>
      </section>
    </BrowserRouter>
  );
};

export default Nav;

import React from 'react'
import './footer.css'

const Footer = () => {
  return (
    <section className="f-wrapper">
        <div className="paddings innerWidth flexCenter f-container">
            {/* left side */}
            <div className="flexColStart f-left">
                <img src="./images/logo.png" alt="" width={120} className='f-logo'/>

                <span className='secondaryText f-text'>
                    Our vision is to make all people <br />
                    the best place to live for them.
                </span>
            </div>

            {/* right side */}
            <div className="flexColStart f-right">
                <span className='secondaryText'>Omega Green Park, Uttardhona, Ayodhya Road, Lucknow - 226028</span>
                <span className='secondaryText'>Phone - +91-7838273237, +91-7007956809</span>
            </div>
        </div>
    </section>
  )
}

export default Footer

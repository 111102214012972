import React from 'react'
import "./companies.css"

const Companies = () => {
  return (
    <section className="c-wrapper" id='partners'>
        <div className="company c-container">
            {/* <img src="./images/partner.png" alt="partner-1"/> */}

            <span className='company-text'>Partner: </span>
            <span className='company-text'>Agarwwal Enterprise</span>
        </div>
    </section>
  )
}

export default Companies

import React from 'react'
import { Swiper, SwiperSlide, useSwiper } from "swiper/react"
import "swiper/css"
import "./work.css"
import data from "../../utils/slider.json"
import { sliderSettings } from '../../utils/common'

const Work = () => {
  return (
    <section className="r-wrapper" id='work'>
        <div className="paddings innerWidth r-container">
            <div className="r-head flexColStart">
                <span className='primaryText'>Our Work</span>
                <div className='work-underline' />
                <span className="secondaryText sub-head">Experience the Transformation - Our Work, Your Inspiration.</span>
            </div>

            <div>
                <Swiper {...sliderSettings}>
                    <SliderButtons />
                    {
                        data.map((card, i) => (
                            <SwiperSlide key={i} >
                                <div className="flexColStart r-card">
                                    <img src={card.image} alt="home" />

                                    <span className='secondaryText r-price'>
                                    <span style={{color: "orange"}}>{card.status}</span>
                                    {/* <span>{card.price}</span> */}
                                    </span>

                                    <span className='primaryText'>{card.name}</span>
                                    {/* <span className='secondaryText'>{card.detail}</span> */}
                                </div>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>
        </div>
    </section>
  )
}

export default Work

const SliderButtons = () => {
    const swiper = useSwiper()

    return (
        <div className="r-buttons flexCenter">
            <button onClick={ () => swiper.slidePrev() }>&lt;</button>
            <button onClick={ () => swiper.slideNext() }>&gt;</button>
        </div>
    )
}
import { BsArrowRight } from 'react-icons/bs'
import { motion } from 'framer-motion'
import { fadeIn } from "../../variants"

import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

import './contact.css'

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_4v5zn0k', 'template_hnzd9ff', form.current, '1Ie7I0yCe4UeafZrX')
      .then((result) => {
          console.log(result.text);
          console.log("message sent");
          e.target.reset()
          alert("Mail Sent!!");
      }, (error) => {
          console.log(error.text);
      });
    }

  return (
    <div className='contact-main' id='contact'>

      <div className='contact-flex'>
        {/* left-side */}
        <div>
          <div>
            {/* text */}
            <motion.h2
              variants={fadeIn('up', 0.2)}
              initial='hidden'
              animate='show'
              exit='hidden'
              className='connect-heading'
            >
              Contact us
              <div className='contact-under-line' />
            </motion.h2>

            {/* form */}
            <motion.form
              ref={form}
              onSubmit={sendEmail}
              initial={{y: "2rem", opacity: 0}}
              whileInView={{y: "0", opacity: 1}}
              transition={{
              duration: 3,
              type: "spring"}}
              className='form'
            >
              <div>
                <input type="text" placeholder='Full Name' className='contact-form' name="to_name" />
                <input type="text" placeholder='Email' className='contact-form' name="from_name" />
              </div>

              <div>
                {/* <div>
                  <input type="text" placeholder='subject' className='contact-form' />
                </div> */}

                <div className='textarea-div'>
                  <textarea placeholder='Message' className='textarea' name="message"></textarea>
                </div>
              </div>

              <button
                type="submit"
                value="send"
                className='form-btn'
              >
                <span>
                  Let&apos;s talk
                </span>
                <span> </span>
                <BsArrowRight/>
              </button>
            </motion.form>
          </div>
        </div>

        {/* mid-side */}
        <div>
          <br /><br /><br /><br />
          <div className='line-1 line-pos' />
          <br />
          <div className='line-1 line-pos' />
        </div>

        {/* right-side */}
        <motion.div
        initial={{x: "2rem", opacity: 0}}
        whileInView={{x: "0", opacity: 1}}
        transition={{
        duration: 4,
        type: "spring"}}
        className="c-right">
            <div className="contact-image">
              <img src="./images/11.png" alt="" />
            </div>
        </motion.div>
      </div>
    </div>
  )
};

export default Contact;

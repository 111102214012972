import React from 'react'
import './about.css'
import {motion} from 'framer-motion'
import { navVariants } from '../../utils/motion';

const About = () => {
  return (
    <div className='about-main' id='about'>
      {/* left-side */}
      <div className="about-flex about-left">
        <div className="r-head flexColStart">
            {/* <span className='orangeText'>Best Choices</span> */}
              <span className='headingText'>About</span>
              <div className='under-line' />
        </div>

        <div>
            <motion.div
              variants={navVariants}
              initial="hidden"
              whileInView="show"   
              className="about-image">
              <img src="./images/mb-pic.png" alt="hero-image" />
            </motion.div>

            <motion.h4
              initial={{x: "-2rem", opacity: 0}}
              whileInView={{x: "0", opacity: 1}}
              transition={{
                duration: 4,
                  type: "spring"
              }}
              className='name'>
                Mayank Gupta
            </motion.h4>
          </div>
        </div>

        {/* right-side */}
        <motion.div
              initial={{x: "-2rem", opacity: 0}}
              whileInView={{x: "0", opacity: 1}}
              transition={{
                duration: 6,
                  type: "spring"
              }}
              className='about-right aboutText'>
                <p>Founder and CMD of GY Consultant & Constructions.
                    Currently working as an Independent Structural Consultant &
                    Construction Contractor. Conversant with analysis and design
                    of RCC & Steel structures such as Educational, Institutional,
                    Assembly, Residential, Office Buildings, Industrial Warehouses
                    & Sheds etc.
                    <br /><br />
                    In-depth knowledge of Indian Standards Code. Structural
                    Analysis, calculations & Design of RCC & Steel Structures as
                    per Indian Design Standards, such as Educational, Institutional,
                    Assembly, Residential Building, Industrial Warehouses & Sheds
                    etc.. Preparation of structural drawings/ detailing utilising
                    Auto-CAD, with the assist of Software and as per IS Code.
                    <br /><br />
                    Experience in Analysis of RCC & Steel framed structures and
                    Steel Portal Frame Structures and its design with the assist of
                    software's like ETABS, Tekla & STAAD.Pro followed by manual
                    checks. Checking the safety of design for Earthquake & Wind
                    Loads as per Indian Design Standards. STAAD Foundation
                    Advanced/CSI SAFE software for structural analysis & design
                    of foundation systems such as raft foundation, pile
                    foundations, combined footing, raft supported on piles as per
                    Indian Standard.
                    <br /><br />
                    Post Graduate in Structural Engineering & Graduate in Civil
                    Engineering with First class with distinction.
                    I focus on my skills for becoming an efficient Structural
                    Engineer & also considering the environmental impact of a
                    project and assessing its criteria.
                </p>
        </motion.div>
      </div>
  )
}

export default About
